import { Capacitor } from "@capacitor/core";
import { bluetoothle } from "./bluetoothle";
import wait from "../helper/wait";
import { START_SCAN } from "./error_codes";
import LogService from "../../services/LogService";
import { serviceUUID } from "./constants";

export type ScanResult = Omit<
  BLECentralPlugin.PeripheralData & {
    address: string;
  },
  "id"
>;
/**
 * Promisify of [bluetoothle.stopScan](https://github.com/don/cordova-plugin-ble-central#stopscan)
 */
const stopScan = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    bluetoothle.stopScan(
      () => resolve(true),
      () => {
        LogService.error(`Error - ${START_SCAN} : Fail to stop scan`);
        // TODO fix this
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`Error - ${START_SCAN} : Fail to stop scan`);
      }
    );
  });
/**
 * Promisify of [bluetoothle.startScan](https://github.com/don/cordova-plugin-ble-central#startscan)
 * @param [services = []]
 * @param [scanFor = 1500]
 * @returns An array of PeripheralData, one for each scanned device
 */
const startScan = (
  services: string[] = [],
  scanFor = 1500
): Promise<ScanResult[]> => {
  // Map from device id to PeripheralData
  const devices: { [address: string]: ScanResult } = {};

  return new Promise((resolve, reject) => {
    bluetoothle.startScanWithOptions(
      // List of services to discover, [] for all
      services,
      // Options
      {
        // This is enabled so that subsequent updates after the first one will be triggered.
        // We want to use the latest values for each device. Duplicate devices (by id) must be
        // removed.
        reportDuplicates: true,
      },
      (peripheralData) => {
        // The success callback may be called multiple times for the same or different devices
        // Use the latest values for each device
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...scanResult } = {
          ...peripheralData,
          // Rename .id -> .address
          address: peripheralData.id,
        };
        devices[peripheralData.id] = scanResult;
      },
      (error) => {
        LogService.error(
          new Error(
            `Error - ${START_SCAN}  : scan error: Fail to scan, reason: ${JSON.stringify(
              error
            )}`
          )
        );
        reject(
          new Error(
            `Error - ${START_SCAN}  : Failed to scan. Ensure bluetooth is on`
          )
        );
      }
    );

    wait(scanFor)
      .then(() => stopScan())
      .then(() => {
        resolve(Object.values(devices));
      })
      .catch((error: Error) => reject(error));
  });
};

const iosGetKnownBondedDevices = async () => {
  return new Promise<Omit<ScanResult, "rssi">[]>((resolve, reject) => {
    bluetoothle.connectedPeripheralsWithServices(
      [serviceUUID],
      (devices) =>
        resolve(devices.map((device) => ({ ...device, address: device.id }))),
      reject
    );
  });
};

/**
 * Get bonded devices
 * These devices may or may not be connected.
 */
const getBondedDevices = (): Promise<Omit<ScanResult, "rssi">[]> => {
  const platform = Capacitor.getPlatform();
  if (platform === "ios") {
    return iosGetKnownBondedDevices();
  }
  return new Promise((resolve, reject) => {
    bluetoothle.bondedDevices(
      (peripheralData) => {
        resolve(
          peripheralData.map((data) => {
            const { id, ...peripheral } = data;

            return {
              ...peripheral,
              address: id,
            };
          })
        );
      },
      () => {
        reject(new Error(`Error: Failed to get bonded devices`));
      }
    );
  });
};

/**
 * Resolves to true if the device is connected
 */
const isDeviceConnected = (address: string): Promise<boolean> =>
  new Promise((resolve) => {
    bluetoothle.isConnected(
      address,
      () => {
        resolve(true);
      },
      () => {
        resolve(false);
      }
    );
  });
const getDeviceRssi = (address: string): Promise<number> =>
  new Promise((resolve, reject) => {
    bluetoothle.readRSSI(
      address,
      (rssi) => {
        resolve(rssi);
      },
      (error) => {
        reject(error);
      }
    );
  });

const getBondedConnectedDevices = async () => {
  // Get bonded devices, which may or may not be connected
  const bondedDevices = await getBondedDevices();
  // Filter non-connected devices by calling the isDeviceConnected function
  const connectedDevices = await Promise.all(
    bondedDevices.map(async (device) => {
      // Bonded device might be connected or not connected
      const isConnected = await isDeviceConnected(device.address);

      // Don't display disonnected devices
      if (!isConnected) {
        return null;
      }

      // Bonded devices don't include rssi, so we read it for each device
      const rssi = await getDeviceRssi(device.address);

      return {
        ...device,
        rssi,
      };
    })
  );

  // Return the devices as an array
  return connectedDevices.filter((device) => device !== null) as ScanResult[];
};
/**
 * Returns true if the device should be displayed as available to connect to
 */
const isDeviceAvailable = (
  deviceName: string,
  miniRSSI: number,
  device: ScanResult
) =>
  device.name && device.name.indexOf(deviceName) >= 0 && device.rssi > miniRSSI;
/**
 * Scan for devices with given parameters, return an array of devices.
 * @param [services=[]] - A list of services, {@link scan()} only returns devices found with given services.
 * @param [deviceName=''] - Filter device name, scan() will only return devices which have given device name.
 * @param [scanFor=1200] - The duration (in ms) of scanning.
 * @param [maxRetry=3] - The retry times for scanning. If maxRetry = 2, scan will retry once if the first scan did not find any device.
 * @param [miniRSSI=-200] -
 * @param [retryInterval=500]
 */
const scan = async (
  services: string[] = [],
  deviceName = "",
  scanFor = 1200,
  maxRetry = 3,
  miniRSSI = -200,
  retryInterval = 500
): Promise<ScanResult[]> => {
  const platform = Capacitor.getPlatform();
  let scanResult: ScanResult[] = [];
  let times = 1;

  while (scanResult.length === 0 && times <= maxRetry) {
    try {
      // Stop scanning if we're already scanning
      // eslint-disable-next-line no-await-in-loop
      await stopScan();
    } catch (error) {
      //
    }
    // On Android scan doesn't include bonded devices.
    // Therefore we combine scanned and bonded devices to show all devices.
    // Note that bonded devices lack rssi data
    // eslint-disable-next-line no-await-in-loop
    const [devices, bondedDevices] = await Promise.all([
      startScan(services, scanFor),
      getBondedConnectedDevices(),
    ]);

    scanResult = [...devices, ...bondedDevices].filter((device) =>
      isDeviceAvailable(deviceName, miniRSSI, device)
    );

    LogService.log(
      `[BLE] scan, scan ${times} time; scan for ${scanFor}ms; scanResult length ${
        scanResult.length
      } ${
        platform === "android" ? `bonded ${bondedDevices.length} ` : ""
      }max retry = ${maxRetry}`
    );

    if (scanResult.length === 0) {
      LogService.log(`[BLE] scan, wait for ${retryInterval + 100 * times}ms`);
      // eslint-disable-next-line no-await-in-loop
      await wait(retryInterval + 100 * times);
    }

    times += 1;
  }

  return scanResult;
};

export { getDeviceRssi, isDeviceConnected, scan, startScan, stopScan };
export default scan;
