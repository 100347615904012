import { AxiosResponse } from "axios";
import axios from "../../AxiosInstance";
import StoredMeasurementHistoryItem from "../../types/contracts/StoredMeasurementHistoryItem";
import { HttpConfig, PagedResult, PagingData } from "../../types/types-api";
import Urls from "../Urls";
import { getUserContentByToken } from "./UserService";
import { Note } from "../../types/contracts/MeasurementHistoryItem";
import { KeyValueParams } from "../../components/filter/datafilter-types";
import { encodeKeyValuesParams } from "../../lib/helper/keyValueParams";

const getUserMeasurementHistory = async (
  username: string,
  paging: PagingData,
  metadata?: KeyValueParams
): Promise<PagedResult<StoredMeasurementHistoryItem>> => {
  const httpConfig: HttpConfig = {
    method: "get",
    params: { ...paging, ...encodeKeyValuesParams(metadata) },
    url: Urls.userMeasurementsByUsername(username),
  };

  const httpResult = await axios(httpConfig);
  const { page, perPage, total, entities } = httpResult.data;
  return { paging: { page, perPage, total }, entities };
};

const getMeasurementHistory = async (
  paging: PagingData,
  metadata?: KeyValueParams
): Promise<PagedResult<StoredMeasurementHistoryItem>> => {
  const httpConfig: HttpConfig = {
    method: "get",
    params: { ...paging, ...encodeKeyValuesParams(metadata) },
    url: Urls.drugAuthentications(),
  };

  const httpResult = await axios(httpConfig);
  const { page, perPage, total, entities } = httpResult.data;
  return { paging: { page, perPage, total }, entities };
};

const getMeasurementHistoryItem = async (
  userId: string,
  measurementId: string
): Promise<StoredMeasurementHistoryItem> => {
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.singleUserMeasurementByUsername(userId, measurementId),
  };
  const httpResult: AxiosResponse<StoredMeasurementHistoryItem> = await axios(
    httpConfig
  );

  return httpResult.data;
};

const getMeasurementHistoryItemWithUserContent = async (
  userId: string,
  measurementId: string
): Promise<StoredMeasurementHistoryItem> => {
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.singleUserMeasurementByUsername(userId, measurementId),
  };

  const httpResult: AxiosResponse<StoredMeasurementHistoryItem> = await axios(
    httpConfig
  );

  const userContent = await getUserContentByToken();
  const resultNotes = httpResult.data.notes as Note[];

  const historyItemNotes = await Promise.all(
    resultNotes.map(async (note) => {
      const noteUserContent = await getUserContentByToken();
      return { ...note, userContent: noteUserContent };
    })
  );

  const historyItem = {
    ...httpResult.data,
    userContent,
    notes: historyItemNotes,
  };

  return historyItem;
};

const addMeasurementHistoryNote = async (
  userId: string,
  measurementId: string,
  message: string
): Promise<StoredMeasurementHistoryItem> => {
  const httpConfig: HttpConfig = {
    method: "post",
    url: Urls.userMeasurementNote(userId, measurementId),
    data: { message },
  };
  const httpResult: AxiosResponse<StoredMeasurementHistoryItem> = await axios(
    httpConfig
  );
  return httpResult.data;
};

const exportMeasurementHistoryCSVFile = async (
  filterParams: KeyValueParams
): Promise<string> => {
  const params = filterParams
    .filter((filter) => filter.value !== "")
    .reduce((acc, filter) => {
      const { key, value } = filter;
      return key ? { ...acc, [key]: value } : acc;
    }, {});
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.exportMeasurementHistoryCsv(),
    params,
  };
  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

export type UniqueValues = {
  metadataValues: Record<string, string[] | null>;
  values: Record<string, string[] | null>;
  usernameMapping: Record<string, string>;
};

// Special case where backends Fastapi-framework doesn't support axios arrays
const getUserMeasurementUniqueValues = async (
  username: string,
  metadataKeys?: string[],
  keys?: string[]
): Promise<UniqueValues> => {
  const params = new URLSearchParams();
  if (metadataKeys) {
    metadataKeys.forEach((value) => params.append("metadataKeys", value));
  }
  if (keys) {
    keys.forEach((value) => params.append("keys", value));
  }

  const httpConfig: HttpConfig = {
    method: "get",
    url: `${Urls.userMeasurementsUniqueValuesByUsername(
      username
      // We parse param to the url because the cordova http plugin that support
      // SSL pinning does not support array based params well.
    )}?${params.toString()}`,
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getMeasurementUniqueValues = async (
  metadataKeys?: string[],
  keys?: string[]
): Promise<UniqueValues> => {
  const params = new URLSearchParams();
  if (metadataKeys) {
    metadataKeys.forEach((value) => params.append("metadataKeys", value));
  }
  if (keys) {
    keys.forEach((value) => params.append("keys", value));
  }

  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.drugAuthenticationsUniqueValues(),
    params,
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const HistoryService = {
  addMeasurementHistoryNote,
  getUserMeasurementHistory,
  getUserMeasurementUniqueValues,
  getMeasurementUniqueValues,
  getMeasurementHistoryItem,
  getMeasurementHistory,
  getMeasurementHistoryItemWithUserContent,
  exportMeasurementHistoryCSVFile,
};

export default HistoryService;
