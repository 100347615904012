import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { ActionSheet, ActionSheetButtonStyle } from "@capacitor/action-sheet";
import { decode } from "base64-arraybuffer";

import { Capacitor } from "@capacitor/core";
import axios from "../../AxiosInstance";
import Urls from "../Urls";

interface GetPhotoParams {
  callback?(photo: Photo): void;
  alert?(message: string): void;
}

const base64ToBlob = (base64: string): Blob => {
  const blobPart = new Uint8Array(decode(base64 as string));
  return new Blob([blobPart]);
};

const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getMeasurementImage = async (
  username: string,
  measurementId: string,
  imageId: string
): Promise<Blob> => {
  const httpConfig: AxiosRequestConfig = {
    method: "get",
    responseType: "blob",
    url: Urls.downloadImage({ username, measurementId, imageId }),
  };
  const httpResult: AxiosResponse = await axios(httpConfig);
  // eslint-disable-next-line prettier/prettier
  return httpResult.data;
};

const uploadMeasurementImage = async (
  username: string,
  measurementId: string,
  data: FormData
): Promise<boolean> => {
  const httpConfig: AxiosRequestConfig = {
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    url: Urls.uploadImage(username, measurementId),
    data,
  };
  await axios(httpConfig);
  return true;
};

const getMeasurementSpectra = async (measurementId: string): Promise<Blob> => {
  const httpConfig: AxiosRequestConfig = {
    method: "get",
    responseType: "blob",
    url: Urls.drugAuthenticationSpectra(measurementId),
  };
  const httpResult: AxiosResponse<Blob> = await axios(httpConfig);
  // eslint-disable-next-line prettier/prettier
  return httpResult.data;
};

const getPhoto = async (params: GetPhotoParams): Promise<Photo | null> => {
  const { callback, alert: confirm = window.confirm } = params;

  const options = [
    {
      title: "Open Camera",
      permissionKey: "camera",
      shouldAskForPermission: true,
      message: "Please grant access to camera to take a picture",
      pictureSourceType: CameraSource.Camera,
    },
    {
      title: "Open Photo Gallery",
      permissionKey: "photos",
      // On iOS, photo gallery picker does not grant photo access
      // Therefore we don't need to explicitly ask for permission
      shouldAskForPermission: Capacitor.getPlatform() === "android",
      message: "Please grant access to photo gallery to pick a picture",
      pictureSourceType: CameraSource.Photos,
    },
  ] as const;

  const { index: selectedOptionIndex } = await ActionSheet.showActions({
    title: "Photo Options",
    message: "Select an option to perform",
    options: [
      {
        title: options[0].title,
      },
      {
        title: options[1].title,
      },
      {
        title: "Cancel",
        style: ActionSheetButtonStyle.Destructive,
      },
    ],
  });

  const optionMetadata = options[selectedOptionIndex];
  // User didn't cancel the image picker
  if (optionMetadata) {
    if (optionMetadata.shouldAskForPermission) {
      const result = await Camera.requestPermissions({
        permissions: [optionMetadata.permissionKey],
      });
      if (result[optionMetadata.permissionKey] === "denied") {
        confirm(optionMetadata.message);
        return null;
      }
    }
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: optionMetadata.pictureSourceType,
      quality: 10,
      width: 1024,
      // We limit the size of the image to make sure the size is not too much and backend will reject it
    });
    if (callback) {
      callback(photo);
    }
    return photo;
  }
  return null;
};

const ImageService = {
  base64ToBlob,
  blobToBase64,
  getMeasurementImage,
  getMeasurementSpectra,
  getPhoto,
  uploadMeasurementImage,
};

export default ImageService;
