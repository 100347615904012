import { KeyValueParams } from "../../components/filter/datafilter-types";

export const encodeKeyValuesParams = (
  params: KeyValueParams | undefined
): Record<string, string | number> => {
  if (!params) {
    return {};
  }
  return params.reduce((acc, param) => {
    if (param.key) {
      return { ...acc, [param.key]: encodeURIComponent(param.value) };
    }
    return acc;
  }, {} as Record<string, string | number>);
};
